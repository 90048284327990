
import { Component, Vue } from 'vue-property-decorator'
import { Material, Warehouse, MaterialType } from '@/types/material.d'
import { Page } from '@/types/common.d'
import ImportData from '@/components/importTemplateData/Index.vue'

@Component({
  components: {
    ImportData
  }
})
export default class Manage extends Vue {
    page = 1
    size = 10
    total = 0
    loading = false
    tableData: Material[] = []

    importVisible = false
    importUrl = this.$apis.material.importNewMaterial

    searchForm = {
      material: '', // 物料名称
      materialTypeId: '', // 物料分类
      state: '' // 状态1启用2禁用
    }

    dialogVisible1 = false
    dialogVisible2 = false

    warehouseData: Warehouse[] = []
    materialTypeData: MaterialType[] = []
    materialTypeForm = {
      materialTypeId: '',
      materialTypeName: '',
      parentMaterialTypeId: ''
    }

    props = {
      value: 'materialTypeId',
      label: 'materialTypeName',
      children: 'childNode'
    }

    get projectList () {
      return this.$store.state.projectList
    }

    created () {
      this.loadData()
      this.loadMaterialType()
    }

    loadData () {
      this.loading = true
      this.$axios.get<Page<Material>>(this.$apis.material.selectMaterialByPage, {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      }).finally(() => {
        this.loading = false
      })
    }

    importSuccess () {
      this.importVisible = false
      this.loadData()
    }

    closeCascader () {
      (this.$refs.cascader as any).dropDownVisible = false
    }

    changeState (data: Material) {
      this.$axios.post(this.$apis.material.updateMaterial, data)
    }

    showMaterialType () {
      this.dialogVisible1 = true
      this.loadMaterialType()
    }

    addMaterialType (parentMaterialTypeId: string) {
      this.$prompt('物料分类名称', '新增物料分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /./,
        inputErrorMessage: '请输入分类名称',
        inputValue: ''
      }).then(({ value }: any) => {
        this.materialTypeForm.materialTypeId = ''
        this.materialTypeForm.parentMaterialTypeId = parentMaterialTypeId
        this.materialTypeForm.materialTypeName = value
        this.saveMaterialType()
      })
    }

    editMaterialType (data: any) {
      console.log('data', data)
      this.$prompt('物料分类名称', '编辑物料分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /./,
        inputErrorMessage: '请输入分类名称',
        inputValue: data.materialTypeName
      }).then(({ value }: any) => {
        this.materialTypeForm.materialTypeId = data.materialTypeId
        this.materialTypeForm.parentMaterialTypeId = data.parentMaterialTypeId
        this.materialTypeForm.materialTypeName = value
        this.saveMaterialType()
      })
    }

    saveMaterialType () {
      let url = this.$apis.material.insertMaterialType
      if (this.materialTypeForm.materialTypeId) {
        url = this.$apis.material.updateMaterialType
      }
      this.$axios.post(url, this.materialTypeForm).then(() => {
        this.loadMaterialType()
      })
    }

    deleteMaterialType (node: any) {
      console.log('node', node)
      this.$axios.post(this.$apis.material.deleteDataMaterialType, {
        materialTypeId: node.data.materialTypeId
      }).then(() => {
        this.loadMaterialType()
      })
    }

    loadMaterialType () {
      this.$axios.get<MaterialType[]>(this.$apis.material.selectMaterialTypeByList).then(res => {
        this.materialTypeData = res
      })
    }

    showWarehouse () {
      this.dialogVisible2 = true
      this.loadWarehouseData()
    }

    addWarehouse () {
      this.warehouseData.push({
        isEdit: true,
        projectId: '', // 项目ID
        projectName: '',
        warehouseName: '', // 仓库名称
        warehouseLiable: '', // 责任人
        warehouseMobile: '', // 联系方式
        warehouseAddress: '' // 仓库地址
      })
    }

    saveWarehouse (data: Warehouse) {
      const item = JSON.parse(JSON.stringify(data)) as Warehouse
      delete item.isEdit
      if (!item.projectId) {
        this.$message({ type: 'warning', message: '请选择项目' })
        return
      }
      if (!item.warehouseName) {
        this.$message({ type: 'warning', message: '请输入仓库名称' })
        return
      }
      if (item.warehouseMobile && !/^[0-9]*$/.test(item.warehouseMobile)) {
        this.$message({ type: 'warning', message: '联系方式为数字' })
        return
      }
      let url = this.$apis.material.insertWarehouse
      if (item.warehouseId) {
        url = this.$apis.material.updateWarehouse
      }
      this.$axios.post(url, item).then(() => {
        this.loadWarehouseData()
      })
    }

    loadWarehouseData () {
      this.$axios.get<Page<Warehouse>>(this.$apis.material.selectWarehouseByList).then(res => {
        this.warehouseData = res.list.map(item => {
          item.isEdit = false
          return item
        })
      })
    }

    onSearch () {
      this.page = 1
      this.loadData()
    }

    deleteRow (materialId: string) {
      this.$confirm('确认删除该物料嘛？', '提示').then(() => {
        this.$axios.post(this.$apis.material.deleteMaterial, {
          materialId
        }).then(() => {
          this.$message.success('删除成功!')
          this.loadData()
        })
      })
    }

    delWarehouse (warehouseId: string, index: number) {
      if (warehouseId) {
        this.$axios.post(this.$apis.material.deleteWarehouse, {
          warehouseId
        }).then(() => {
          this.$message.success('删除成功!')
          this.loadWarehouseData()
        })
      } else {
        this.warehouseData.splice(index, 1)
      }
    }
}
